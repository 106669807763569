import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

export const userTermsAcceptanceQueryOptions = queryOptions({
  queryKey: ["userTermsAcceptance"],
  queryFn: async () => {
    const response = await API.terms.termsControllerAcceptanceCheck();

    return response.data;
  },
});

export const useUserTermsAcceptance = () => {
  return useQuery(userTermsAcceptanceQueryOptions);
};

export const useUserTermsAcceptanceSuspense = () => {
  return useSuspenseQuery(userTermsAcceptanceQueryOptions);
};
