import { RouteObject, createBrowserRouter, redirect } from "react-router-dom";
import { useAuthStore } from "@/stores/auth/auth-store";
import { queryClient } from "@/api/client";
import { currentUserQueryOptions } from "@/api/queries/useCurrentUser";
import { workspaceArtistsQueryOptions } from "@/api/queries/useArtists";
import { artistQueryOptions } from "@/api/queries/useArtist";
import { toursQueryOptions } from "@/api/queries/useTours";
import { GenericError } from "../Errors/GenericError";
import { personalDetailsQueryOptions } from "@/api/queries/usePersonalDetails";
import { travellingDetailsQueryOptions } from "@/api/queries/useTravellingDetails";
import { emergencyContactsQueryOptions } from "@/api/queries/useEmergencyContacts";
import { tourQueryOptions } from "@/api/queries/useTour";
import { tourDaysQueryOptions } from "@/api/queries/useTourDays";
import { eventTypesQueryOptions } from "@/api/queries/useEventTypes";
import { tourEventsQueryOptions } from "@/api/queries/useTourEvents";
import * as Sentry from "@sentry/react";
import { DashboardErrorBoundary } from "../Dashboard/DashboardErrorBoundary";
import { riderQueryOptions } from "@/api/queries/useRider";
import { riderPersonnelQueryOptions } from "@/api/queries/useRiderPersonnel";
import { riderExternalPersonnelQueryOptions } from "@/api/queries/useRiderExternalPersonnel";
import { chatRoomsQueryOptions } from "@/api/queries/useChatRooms";
import { reportQuestionsColumnsQueryOptions } from "@/api/queries/useReportQuestionsColumns";
import { userTermsAcceptanceQueryOptions } from "@/api/queries/useUserTermsAcceptance";
import { userPrivacyPolicyAcceptanceQueryOptions } from "@/api/queries/useUserPrivacyPolicyAcceptance";

const routes = [
  {
    path: "*",
    element: <GenericError is404Page />,
  },
  {
    path: "",
    loader: () => {
      return redirect("/login");
    },
  },
  {
    path: "",
    errorElement: <GenericError />,
    loader: ({ request }) => {
      const accessToken = useAuthStore.getState().authToken;

      if (!accessToken) {
        return {};
      }

      if (request.url.includes("confirm-reset-password")) {
        return {};
      }

      return redirect("/dashboard");
    },
    lazy: () => import("../Auth/Unauthorized.layout"),
    children: [
      {
        path: "login",
        lazy: () => import("../Auth/Login.page"),
      },
      {
        path: "register",
        lazy: () => import("../Auth/Register.page"),
      },
      {
        path: "register-confirm",
        lazy: () => import("../Auth/RegisterConfirm.page"),
      },
      {
        path: "register-complete",
        lazy: () => import("../Auth/RegisterComplete.page"),
      },
      {
        path: "reset-password",
        lazy: () => import("../Auth/ResetPassword.page"),
      },
      {
        path: "reset-password/sent",
        lazy: () => import("../Auth/ResetPasswordConfirm.page"),
      },
      {
        path: "confirm-reset-password",
        lazy: () => import("../Auth/ConfirmResetPassword.page"),
      },
    ],
  },
  {
    path: "onboarding",
    lazy: () => import("../Onboarding/Onboarding.layout"),
    loader: async () => {
      const accessToken = useAuthStore.getState().authToken;
      if (!accessToken) {
        return redirect("/login");
      }

      await queryClient.ensureQueryData(currentUserQueryOptions);

      return {};
    },
    children: [
      {
        path: "",
        lazy: () => import("../Onboarding/Onboarding.page"),
      },
      {
        path: "0",
        lazy: () => import("../Onboarding/0Onboarding.page"),
      },
      {
        path: "1",
        loader: async () => {
          await queryClient.prefetchQuery(personalDetailsQueryOptions);
          return {};
        },
        lazy: () => import("../Onboarding/1Onboarding.page"),
      },
      {
        path: "2",
        loader: async () => {
          await queryClient.prefetchQuery(personalDetailsQueryOptions);
          return {};
        },
        lazy: () => import("../Onboarding/2Onboarding.page"),
      },
      {
        path: "3",
        loader: async () => {
          await queryClient.prefetchQuery(personalDetailsQueryOptions);
          return {};
        },
        lazy: () => import("../Onboarding/3Onboarding.page"),
      },
      {
        path: "4",
        loader: async () => {
          await queryClient.prefetchQuery(personalDetailsQueryOptions);
          return {};
        },
        lazy: () => import("../Onboarding/4Onboarding.page"),
      },
      {
        path: "5",
        loader: async () => {
          await queryClient.prefetchQuery(travellingDetailsQueryOptions);
          return {};
        },
        lazy: () => import("../Onboarding/5Onboarding.page"),
      },
      {
        path: "6",
        loader: async () => {
          await queryClient.prefetchQuery(travellingDetailsQueryOptions);
          return {};
        },
        lazy: () => import("../Onboarding/6Onboarding.page"),
      },
      {
        path: "7",
        loader: async () => {
          await queryClient.prefetchQuery(personalDetailsQueryOptions);
          return {};
        },
        lazy: () => import("../Onboarding/7Onboarding.page"),
      },
      {
        path: "8",
        loader: async () => {
          await queryClient.prefetchQuery(emergencyContactsQueryOptions);
          return {};
        },
        lazy: () => import("../Onboarding/8Onboarding.page"),
      },
    ],
  },
  {
    path: "/terms-and-conditions",
    lazy: () => import("../TermsAndConditions/TermsAndConditions.layout"),
    loader: async () => {
      const accessToken = useAuthStore.getState().authToken;
      if (!accessToken) {
        return redirect("/login");
      }

      await queryClient.ensureQueryData(currentUserQueryOptions);

      return {};
    },
  },
  {
    path: "dashboard",
    lazy: () => import("../Dashboard/Dashboard.layout"),
    errorElement: <GenericError />,
    loader: async () => {
      const accessToken = useAuthStore.getState().authToken;
      if (!accessToken) {
        return redirect("/login");
      }

      await queryClient.prefetchQuery(userTermsAcceptanceQueryOptions);
      await queryClient.prefetchQuery(userPrivacyPolicyAcceptanceQueryOptions);
      const areTermsAccepted = queryClient.getQueryData(
        userTermsAcceptanceQueryOptions.queryKey
      )?.accepted;
      const arePrivacyPolicyAccepted = queryClient.getQueryData(
        userPrivacyPolicyAcceptanceQueryOptions.queryKey
      )?.accepted;

      if (!areTermsAccepted || !arePrivacyPolicyAccepted) {
        return redirect("/terms-and-conditions");
      }

      const [currentUser] = await Promise.all([
        queryClient.ensureQueryData(currentUserQueryOptions),
        queryClient.ensureQueryData(workspaceArtistsQueryOptions),
      ]);

      const showOnboarding =
        !currentUser.status.onboardingCompleted &&
        !currentUser.status.onboardingSkipped;

      if (showOnboarding) {
        return redirect("/onboarding");
      }

      return {};
    },
    children: [
      {
        path: "",
        element: <DashboardErrorBoundary fallback={<GenericError />} />,
        children: [
          {
            path: "*",
            element: <GenericError is404Page />,
          },
          {
            path: "",
            loader: async () => {
              await queryClient.prefetchQuery(toursQueryOptions());
              return {};
            },
            lazy: () => import("../Dashboard/Dashboard.page"),
          },
          {
            path: "profile",
            lazy: () => import("../Profile/Profile.page"),
          },
          {
            path: "resources",
            lazy: () => import("../Dashboard/Resources/Resources.page"),
          },
          {
            path: "library",
            lazy: () => import("../Dashboard/FileLibrary/FileLibrary.page"),
          },
          {
            path: "profile/change-password",
            lazy: () => import("../Profile/ChangePassword.page"),
          },
          {
            path: "profile/personal-details",
            lazy: () => import("../Profile/Forms/PersonalDetailsForm.page"),
          },
          {
            path: "profile/travelling-details",
            lazy: () => import("../Profile/Forms/TravellingDetailsForm.page"),
          },
          {
            path: "profile/emergency-contacts",
            lazy: () => import("../Profile/Forms/EmergencyContactForm.page"),
          },
          {
            path: "messages",
            loader: async () => {
              await queryClient.prefetchQuery(chatRoomsQueryOptions);

              return {};
            },
            lazy: () => import("../Messages/Messages.page"),
          },
          {
            path: "messages/:chatId/edit",
            lazy: () => import("../Messages/CreateChatRoomForm.page"),
          },
          {
            path: "messages/add",
            lazy: () => import("../Messages/CreateChatRoomForm.page"),
          },
          {
            path: "artists/:artistId",
            loader: async ({ params }) => {
              if (!params.artistId) {
                return {};
              }

              await Promise.all([
                queryClient.prefetchQuery(artistQueryOptions(params.artistId)),
                queryClient.prefetchQuery(
                  toursQueryOptions({
                    filter: {
                      artistId: params.artistId,
                    },
                  })
                ),
              ]);

              return {};
            },
            lazy: () => import("../Artist/Artist.page"),
          },
          {
            path: "artists/create",
            lazy: () => import("../Artist/CreateArtist.page"),
          },
          {
            path: "artists/:artistId/edit",
            loader: async ({ params }) => {
              if (!params.artistId) return {};

              await queryClient.prefetchQuery(
                artistQueryOptions(params.artistId)
              );

              return {};
            },
            lazy: () => import("../Artist/EditArtist.page"),
          },
          {
            path: "tours/create",
            lazy: () => import("../Tours/CreateTour.page"),
          },
          {
            path: "tours/create/:artistId",
            lazy: () => import("../Tours/Forms/CreateTourForm.page"),
          },
          {
            path: "tours/create/:artistId/:tourId",
            loader: async ({ params }) => {
              if (!params.tourId) {
                return {};
              }
              return redirect("assign-personnel");
            },
          },
          {
            path: "tours/create/:artistId/:tourId/assign-personnel",
            lazy: () => import("../Tours/Forms/AssignPersonnelTourForm.page"),
          },
          {
            path: "tours/update/:artistId/:tourId",
            lazy: () => import("../Tours/Forms/UpdateTourForm.page"),
            loader: async ({ params }) => {
              if (!params.tourId) {
                return {};
              }
              await Promise.all([
                queryClient.prefetchQuery(tourQueryOptions(params.tourId)),
                queryClient.prefetchQuery(tourDaysQueryOptions(params.tourId)),
              ]);

              return {};
            },
          },
          {
            path: "tours/update/:artistId/:tourId/assign-personnel",
            lazy: () => import("../Tours/Forms/AssignPersonnelTourForm.page"),
          },
          {
            path: "tours/:tourId",
            lazy: () => import("../Tours/Tour.layout"),
            loader: async ({ params }) => {
              const tourId = params.tourId;
              if (!tourId) {
                return {};
              }

              await queryClient.prefetchQuery(tourQueryOptions(tourId));
              await queryClient.prefetchQuery(tourDaysQueryOptions(tourId));
              await queryClient.prefetchQuery(eventTypesQueryOptions);
              await queryClient.prefetchQuery(tourEventsQueryOptions(tourId));
              return {};
            },
            children: [
              {
                path: "",
                lazy: () => import("../Tours/Tour.page"),
              },
              {
                path: "personnel",
                lazy: () => import("../Tours/Personnel/TourPersonnel.page"),
              },
              {
                path: "events",
                lazy: () => import("../Tours/Events/Events.page"),
              },
              {
                path: "daysheets/:tourDay",
                lazy: () => import("../Tours/Daysheets/Daysheets.page"),
              },
              {
                path: "accommodation/:eventId",
                lazy: () => import("../Tours/Accommodation/Accommodation.page"),
              },
              {
                path: "riders",
                lazy: () => import("../Tours/Riders/Riders.page"),
              },
              {
                path: "riders/create",
                lazy: () => import("../Tours/Riders/RiderForm.page"),
              },
              {
                path: "riders/:riderId",
                lazy: () => import("../Tours/Riders/Rider.layout"),
                loader: async ({ params }) => {
                  if (!params.riderId) return {};

                  await queryClient.prefetchQuery(
                    riderQueryOptions(params.riderId)
                  );
                  return {};
                },
                children: [
                  {
                    index: true,
                    loader: async ({ params }) => {
                      if (!params.riderId) return {};

                      await queryClient.prefetchQuery(
                        riderPersonnelQueryOptions(params.riderId)
                      );
                      await queryClient.prefetchQuery(
                        riderExternalPersonnelQueryOptions(params.riderId)
                      );
                      return redirect("personnel");
                    },
                  },
                  {
                    path: "personnel",
                    lazy: () => import("../Tours/Riders/Rider01Personnel.page"),
                  },
                  {
                    path: "labor",
                    lazy: () => import("../Tours/Riders/Rider02Labor.page"),
                  },
                  {
                    path: "venue",
                    lazy: () => import("../Tours/Riders/Rider03Venue.page"),
                  },
                  {
                    path: "transportation",
                    lazy: () =>
                      import("../Tours/Riders/Rider04Transportation.page"),
                  },
                  {
                    path: "technical",
                    lazy: () => import("../Tours/Riders/Rider05Technical.page"),
                  },
                  {
                    path: "hospitality",
                    lazy: () =>
                      import("../Tours/Riders/Rider06Hospitality.page"),
                  },
                  {
                    path: "merch",
                    lazy: () => import("../Tours/Riders/Rider07Merch.page"),
                  },
                  {
                    path: "supporting-act",
                    lazy: () => import("../Tours/Riders/Rider08Support.page"),
                  },
                ],
              },
              {
                path: "riders/:riderId/edit",
                lazy: () => import("../Tours/Riders/RiderForm.page"),
              },
              {
                path: "riders/:riderId/personnel/add",
                lazy: () => import("../Tours/Riders/RiderContactsAdd.page"),
              },
              {
                path: "riders/:riderId/support-personnel/add",
                lazy: () =>
                  import("../Tours/Riders/RiderSupportContactsAdd.page"),
              },
              {
                path: "riders/:riderId/labor/add",
                lazy: () => import("../Tours/Riders/RiderLaborAdd.page"),
              },
              {
                path: "riders/:riderId/preview",
                lazy: () => import("../Tours/Riders/Preview/RiderPreview.page"),
              },
              {
                path: "messages",
                loader: async () => {
                  await queryClient.prefetchQuery(chatRoomsQueryOptions);

                  return {};
                },
                lazy: () => import("../Messages/Messages.page"),
              },
              {
                path: "messages/:chatId/edit",
                lazy: () => import("../Messages/CreateChatRoomForm.page"),
              },
              {
                path: "messages/:chatId/members",
                lazy: () => import("../Messages/ChatRoomMembers.page"),
              },
              {
                path: "messages/add",
                lazy: () => import("../Messages/CreateChatRoomForm.page"),
              },
              {
                path: "reports",
                lazy: () => import("../Tours/Reports/Reports.page"),
              },
              {
                path: "reports/:reportId",
                lazy: () => import("../Tours/Reports/ReportDetails.page"),
              },
              {
                path: "reports/new/:reportId",
                loader: async () => {
                  await queryClient.prefetchQuery(
                    reportQuestionsColumnsQueryOptions
                  );
                  return {};
                },
                lazy: () =>
                  import("../Tours/Reports/ReportsCustomCreation.page"),
              },
              {
                path: "travel-tracker",
                lazy: () => import("../Tours/Travel/TravelTracker.page"),
              },
              {
                path: "production-tracker",
                lazy: () =>
                  import("../Tours/ProductionTracker/ProductionTracker.page"),
              },
              {
                path: "files",
                lazy: () => import("../Tours/Files/TourFiles.page"),
              },
              {
                path: "supplemental-questions",
                lazy: () =>
                  import(
                    "../Tours/SupplementalQuestions/TourSupplementalQuestions.page"
                  ),
              },
            ],
          },
          {
            path: "admin",
            loader: async () => {
              //TODO: Add admin auth check once implemented on BE
              return {};
            },
            children: [
              {
                path: "users",
                lazy: () => import("../Admin/Users/AdminUsers.page"),
              },
              {
                path: "users/:userId",
                lazy: () => import("../Admin/Users/AdminUserDetails.page"),
              },
              {
                path: "users/create",
                lazy: () => import("../Admin/Users/AdminUserCreation.page"),
              },
              {
                path: "countries",
                lazy: () => import("../Admin/Countries/AdminCountries.page"),
              },
              {
                path: "countries/:countryId",
                lazy: () =>
                  import("../Admin/Countries/AdminCountryDetails.page"),
              },
              {
                path: "countries/create",
                lazy: () =>
                  import("../Admin/Countries/AdminCountryCreation.page"),
              },
            ],
          },
          {
            path: "design-system",
            lazy: () => import("../DesignSystem/DesignSystem.page"),
          },
        ],
      },
    ],
  },
] satisfies RouteObject[];

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

type RouterOpts = Parameters<typeof createBrowserRouter>[1];

export const router = sentryCreateBrowserRouter(routes, {
  future: {
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
  },
} satisfies RouterOpts);
