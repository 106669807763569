import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { API } from "../api";

export const userPrivacyPolicyAcceptanceQueryOptions = queryOptions({
  queryKey: ["userPrivacyPolicyAcceptance"],
  queryFn: async () => {
    const response =
      await API.privacyPolicies.privacyPoliciesControllerAcceptanceCheck();

    return response.data;
  },
});

export const useUserPrivacyPolicyAcceptance = () => {
  return useQuery(userPrivacyPolicyAcceptanceQueryOptions);
};

export const useUserPrivacyPolicyAcceptanceSuspense = () => {
  return useSuspenseQuery(userPrivacyPolicyAcceptanceQueryOptions);
};
